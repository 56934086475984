<template>
    <div class="suiyue liefeng-tabs">
        <Tabs :value="0">
            <TabPane v-for="(item, idx) in menuDatas" :label="item.title" :name="idx" :key="idx">
                <div v-html="item.value"></div>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
export default {
    props: {
        dataFrom: {
            type: String,
            default: "api",
        },
        name: {
            type: String,
            default: "",
        },
        data: {
            type: Array,
            default: () => {
                return [
                    // {
                    //     title:'',
                    //     value:''
                    // }
                ]
            },
        },
        menuCode: { type: String, default: "" }, //当前菜单代码:sysModuleId
    },
    data() {
        return {
            menuDatas: [],
        }
    },
    mounted() {
        //直接读取子菜单
        if (this.dataFrom == "menu") {
            this.getSystemMenu()
        } else {
            this.menuDatas = this.data || []
        }
    },
    methods: {
        getSystemMenu() {
            this.$get("/gateway/api/auth/app/systemModule/cascade", { parentId: this.menuCode }).then(res => {
                if (res && res.code == "200" && res.dataList) {
                    res.dataList.map(item => {
                        if (item.type == "1") {
                            //是菜单
                            item.title = item.name
                            item.value = item.sysModuleId
                            this.menuDatas.push(item)
                        }
                    })
                }
            })
        },
    },
}
</script>

<style lang="less">
.liefeng-tabs {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 9;
    padding: 10px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
}
</style>
